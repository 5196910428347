import { computed } from "vue";

/**
 * @typedef {"h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle-1" | "subtitle-2" | "body-1" | "body-2" | "button" | "caption" | "overline"} MaterialDesignTypography
 * @typedef {Object} TextProps
 * @property {MaterialDesignTypography} text - Material design typography values
 */

/**
 * @param {{ text: MaterialDesignTypography }} [defaultValues]
 */
export const makeTextProps = (defaultValues = {}) => ({
  text: {
    type: String,
    validator: (value) =>
      [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "subtitle-1",
        "subtitle-2",
        "body-1",
        "body-2",
        "button",
        "caption",
        "overline",
      ].includes(value),
    default: defaultValues?.text,
  },
});

/**
 * @param {TextProps} props
 */
export function useText(props) {
  const textClass = computed(() => `text-${props.text}`);
  return { textClass };
}
