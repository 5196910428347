<template>
  <v-fade-transition>
    <div class="note-comment" data-testid="noteComment">
      <v-list-item dense>
        <EmployeeAvatarQuery
          :employee-id="comment.employeeId"
          list-item
          size="32"
        />
        <v-list-item-content class="note-comment__body" v-text="comment.body" />
      </v-list-item>
      <div class="note-comment__details text-caption">
        <span class="mr-2" v-text="time" />
        <NoteCommentLikes :likes="comment.likes" :comment-id="comment.id" />
      </div>
    </div>
  </v-fade-transition>
</template>

<style lang="scss">
.note-comment {
  margin-bottom: 12px;

  .note-comment__body {
    border-radius: 28px;
    padding: 8px 18px;
    flex: 0 1 auto;
  }

  .theme--light .note-comment__body {
    background: #f5f5f5;
  }

  .theme--dark .note-comment__body {
    background: #292929;
  }

  .note-comment__details {
    display: flex;
    margin-left: 82px;
  }
}
</style>

<script>
import EmployeeAvatarQuery from "@/components/employees/EmployeeAvatarQuery";
import NoteCommentLikes from "./NoteCommentLikes/index";
import { relativeTime } from "@/utils/datetime";

export default {
  components: {
    EmployeeAvatarQuery,
    NoteCommentLikes,
  },

  props: {
    comment: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    time() {
      return relativeTime(
        this.comment.createdAt,
        this.$store.getters["bizdate/getBizDate"] || new Date()
      );
    },
  },
};
</script>
