import { hasSnackbarAccess } from "@/mixins/ui";
import {
  injectActiveEmployee,
  hasActiveEmployeeIdAccess,
} from "@/mixins/employee";
import gql from "graphql-tag";

export default {
  mixins: [hasSnackbarAccess, injectActiveEmployee, hasActiveEmployeeIdAccess],

  inheritAttrs: false,

  props: {
    commentId: {
      type: String,
    },
    likes: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    loading: false,
  }),

  methods: {
    async likeComment() {
      this.loading = true;

      try {
        await this.mutateLikeComment({
          id: this.commentId,
          employeeId: this.activeEmployeeId,
        });
      } catch (error) {
        this.showSnackbar({
          text: `Error liking comment. ${error}`,
        });
      } finally {
        this.loading = false;
      }
    },
    mutateLikeComment({ id, employeeId }) {
      return this.$apollo.mutate({
        mutation: gql`
          mutation LIKE_NOTE_COMMENT($id: ID!, $employeeId: ID!) {
            likeNoteComment(id: $id, employeeId: $employeeId) {
              noteComment {
                id
                likes {
                  id
                  createdAt
                  employee {
                    id
                    fullName
                  }
                }
              }
              errors
            }
          }
        `,
        variables: { id, employeeId },
      });
    },
  },
};
