<template>
  <v-card-title class="px-0">
    <v-list-item class="grow dense">
      <EmployeeAvatar :employee="employee" hide-status list-item />
      <v-list-item-content>
        <div class="text-h6 ml-4 mb-0 align-center">
          {{ authorName }}
          <!-- TODO: handle manager content -->
          <v-icon
            v-if="note.isManagerOnly"
            size="20"
            class="ml-1"
            color="warning"
            v-text="`$vuetify.icons.key`"
          />
        </div>
        <span
          class="text-subtitle-1 ml-4 text--secondary"
          v-text="publishedAtTxt"
        />
      </v-list-item-content>

      <v-list-item-action class="mr-0">
        <VxMoreMenu v-model="menu" :disabled="dayIsPast">
          <v-list>
            <EmployeeAction v-slot="{ isDisabled, listeners }" check-manager>
              <v-list-item
                :class="{ 'text--disabled': isDisabled }"
                @click="onEditClick"
                v-on="listeners"
                v-text="`Edit`"
              />
            </EmployeeAction>
          </v-list>
        </VxMoreMenu>
      </v-list-item-action>
    </v-list-item>
  </v-card-title>
</template>

<script>
import VxMoreMenu from "@/components/vx/VxMoreMenu";
import EmployeeAction from "@/components/employees/EmployeeAction.js";
import { EMPLOYEE_DETAIL } from "@/graphql/employee/queries.gql";
import { materialDate } from "@/utils/datetime";

import { format, isYesterday, isToday, parseISO } from "date-fns";
import { get } from "lodash";
import { hasRouteDay } from "@/mixins/routerParams";
import EmployeeAvatar from "../employees/EmployeeAvatar.vue";

export default {
  name: "NoteAuthor",
  components: { VxMoreMenu, EmployeeAction, EmployeeAvatar },
  mixins: [hasRouteDay],
  props: {
    employeeId: {
      type: [String, Number],
      default: null,
    },
    note: {
      type: Object,
      default: () => {},
    },
  },
  apollo: {
    employee: {
      query: EMPLOYEE_DETAIL,
      variables() {
        return this.variables;
      },
      fetchPolicy: "cache-first",
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    authorInitials() {
      const first = get(this.employee, "firstName", "")[0];
      const last = get(this.employee, "lastName", "")[0];
      return `${first}${last}`;
    },
    employee() {
      return get(this.note, "employee");
    },
    authorName() {
      return get(this.employee, "fullName");
    },
    publishedAtTxt() {
      var createdAt = get(this.note, "businessDate");
      var updatedAt = format(
        parseISO(get(this.note, "updatedAt")),
        "yyyy-MM-dd"
      );

      const capitalize = (string) =>
        string.charAt(0).toUpperCase() + string.slice(1);

      if (createdAt != updatedAt) {
        return (
          capitalize(materialDate(createdAt)) +
          ` ∙ Edited ${this.basicRelativeTime(updatedAt)}`
        );
      }
      return capitalize(materialDate(createdAt));
    },
    variables() {
      return {
        id: get(this.employee, "id"),
      };
    },
  },
  methods: {
    onEditClick() {
      return this.$emit("edit");
    },
    basicRelativeTime(date) {
      if (isYesterday(parseISO(date))) return "yesterday";
      if (isToday(parseISO(date))) return "today";

      return format(parseISO(date), "MM/d/yyyy");
    },
  },
};
</script>
