<template>
  <div class="note-comments-list">
    <template v-if="sortedComments.length > 3 && viewAll === false">
      <v-btn
        text
        small
        color="primary"
        @click="toggleViewAll"
        v-text="viewMoreText"
      />
      <NoteComment
        v-for="comment in latestComments"
        :key="`note-comment-${comment.id}`"
        :comment="comment"
      />
    </template>
    <template v-if="viewAll || sortedComments.length <= 3">
      <NoteComment
        v-for="comment in sortedComments"
        :key="`note-comment-${comment.id}`"
        :comment="comment"
      />
    </template>
  </div>
</template>
<script>
import NoteComment from "./NoteComment";
import { sortByDate } from "@/utils/datetime";

export default {
  components: {
    NoteComment,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    viewAll: false,
  }),

  computed: {
    sortedComments() {
      return sortByDate(this.items);
    },
    latestComments() {
      return this.sortedComments.slice(-3);
    },
    viewMoreText() {
      return `View ${
        this.sortedComments.length - this.latestComments.length
      } more`;
    },
  },

  methods: {
    toggleViewAll() {
      return (this.viewAll = !this.viewAll);
    },
  },
};
</script>
