<template>
  <span>
    <v-btn
      :loading="loading"
      rounded
      class="text-caption"
      x-small
      text
      @click="likeComment"
      v-text="`Like`"
    />
  </span>
</template>

<script>
import Base from "./Base";

export default {
  extends: Base,
};
</script>
