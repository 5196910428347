<template>
  <component
    :is="avatarComponent"
    :id="avatarId"
    :class="['employee-avatar', classes]"
    :size="size"
    :color="color"
  >
    <v-tooltip v-if="showTooltip" :activator="`#${avatarId}`" bottom>
      <span>{{ employee.fullName }}</span>
    </v-tooltip>
    <v-fade-transition mode="out-in">
      <v-overlay v-if="loading" absolute opacity="0.2" z-index="0">
        <v-progress-circular indeterminate :size="size - 20" :width="1" />
      </v-overlay>
      <v-img v-else-if="src" :src="src" :alt="alt" />
      <span v-else :class="[textClass, 'white--text']">{{ initials }}</span>
    </v-fade-transition>
  </component>
</template>

<script>
import { defineComponent, computed } from "vue";
import { VAvatar, VListItemAvatar } from "vuetify/lib";
import { convertToUnit } from "vuetify/es5/util/helpers";

import { makeTextProps, useText } from "@/composables/text";
import { makeStatusProps, useStatus } from "@/composables/employee/status";

/**
 * @param {String} fullName - The full name of the person.
 * @returns The initials of the full name.
 */
function createInitials(fullName) {
  return fullName
    .split(" ")
    .map((x) => x.charAt(0).toUpperCase())
    .filter((x) => /[A-Z0-9]/.test(x))
    .slice(0, 3)
    .join("")
    .toUpperCase();
}

export default defineComponent({
  name: "EmployeeAvatar",
  inheritAttrs: false,
  props: {
    size: {
      type: [Number, String],
      default: 40,
    },
    statusSize: {
      type: [Number, String],
      default: 16,
    },
    srcUrl: {
      type: String,
      default: "",
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    listItem: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    ...makeStatusProps(),
    ...makeTextProps({ text: "overline" }),
  },
  setup(props) {
    const { textClass } = useText(props);
    const { statusClasses } = useStatus(props, "employee-avatar");

    const avatarId = computed(() => `employee-avatar-${props.employee.id}`);

    const avatarComponent = computed(() => {
      return props.listItem ? VListItemAvatar : VAvatar;
    });

    const avatarVars = computed(() => ({
      statusSize: convertToUnit(props.statusSize),
    }));

    const classes = computed(() => {
      return props.loading ? {} : statusClasses.value;
    });

    const showTooltip = computed(() => {
      return props.tooltip && props.employee.fullName;
    });

    const src = computed(() => props.employee.avatarUrl ?? props.srcUrl);

    const alt = computed(() => `${props.employee.fullName}'s avatar`);

    const color = computed(() => {
      if (!props.employee.id) return "ui";
      return props.employee.avatarUrl || props.employee.drsEmployeeCode
        ? "primary lighten-1"
        : "secondary lighten-2";
    });

    const initials = computed(() => {
      const { drsEmployeeCode, fullName } = props.employee;
      if (drsEmployeeCode) return drsEmployeeCode;
      return fullName ? createInitials(fullName) : "N / A";
    });

    return {
      avatarId,
      avatarVars,
      avatarComponent,
      classes,
      textClass,
      showTooltip,
      src,
      alt,
      color,
      initials,
    };
  },
});
</script>

<style lang="scss" scoped>
@mixin employee-status($light, $dark) {
  &.v-avatar::after,
  &.v-list-item__avatar::after {
    position: absolute;
    top: -2.5px;
    right: -5px;
    content: "";
    height: v-bind("avatarVars.statusSize");
    width: v-bind("avatarVars.statusSize");
    border: 2px solid transparent;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    z-index: 1;
  }

  .theme--light &.v-avatar::after,
  .theme--light &.v-list-item__avatar::after {
    background: $light;
    border-color: #fff;
  }

  .theme--dark &.v-avatar::after,
  .theme--dark &.v-list-item__avatar::after {
    background: $dark;
    border-color: #1e1e1e;
  }
}

.employee-avatar.v-avatar,
.employee-avatar.v-list-item__avatar {
  position: relative;
  justify-content: center;
  overflow: visible;
  transition: background-color 0.2s ease-in-out;
}

.employee-avatar.employee-avatar--clocked-out {
  opacity: 0.8;
}

.employee-avatar.employee-avatar--clocked-in {
  @include employee-status(var(--v-success-base), var(--v-success-lighten2));
}

.employee-avatar.employee-avatar--break {
  @include employee-status(var(--v-warning-base), var(--v-warning-lighten2));
}
</style>
