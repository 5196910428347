<template>
  <v-btn
    :color="(value && `primary`) || `grey`"
    v-bind="$attrs"
    v-on="$listeners"
    v-text="buttonText"
  />
</template>

<script>
export default {
  name: "ToggleCommentsButton",
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    buttonText() {
      return (
        (this.value === 0 && "Add comment") ||
        (this.value === 1 && "1 comment") ||
        `${this.value} comments`
      );
    },
  },
};
</script>
