<template>
  <v-menu
    v-model="menuModel"
    transition="slide-y-reverse-transition"
    offset-y
    top
    :close-on-content-click="false"
  >
    <template #activator="{ on }">
      <v-btn
        ref="emojiButton"
        class="message-input__emoji-button message-input__button"
        text
        color="grey darken-1"
        v-on="on"
      >
        <v-icon size="24" v-text="`$vuetify.icons.smile`" />
      </v-btn>
    </template>
    <!-- ^emoji picker -->
    <EmojiPicker
      class="message-input__emoji-picker message-input__button"
      :show-skin-tones="false"
      :show-preview="false"
      set="apple"
      title
      emoji="emoji"
      :sheet-size="32"
      :auto-focus="true"
      :native="native"
      @select="handleSelect"
    />
    <!-- $emoji picker -->
  </v-menu>
</template>
<script>
// TODO: move to v-emoji-picker?
export default {
  name: "EmojiPickerMenu",
  components: {
    EmojiPicker: () =>
      import(/* webpackChunkName: "emoji-mart" */ "emoji-mart-vue").then(
        ({ Picker }) => Picker
      ),
  },
  props: {
    native: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    menuModel: false,
  }),
  methods: {
    handleSelect(emoji) {
      this.menuModel = false;
      this.$emit("select", emoji);
    },
  },
};
</script>
