<template>
  <v-fade-transition>
    <VxQuery :query="$options.NOTE_COMMENTS" :variables="queryVariables">
      <template #loading>
        <v-skeleton-loader type="list-item-avatar-two-line" />
      </template>
      <template #error="{ error }">
        <span v-text="error.graphQLErrors.map((e) => e.message).join(`\n`)" />
      </template>
      <template #data="{ data: { noteComments }, query }">
        <!-- Looks like there can only be one child here. Perhaps due to this rendering inside v-card-text -->
        <div>
          <!-- Hack to get access to the SmartQuery object -->
          {{ setQuery(query) }}
          <NoteCommentsList :items="noteComments" />
          <AddCommentInput
            v-model="addCommentText"
            :loading="addCommentLoading"
            @submit="onCommentSubmit"
          />
        </div>
      </template>
    </VxQuery>
  </v-fade-transition>
</template>

<script>
import VxQuery from "@/components/vx/VxQuery";
import AddCommentInput from "./AddCommentInput.vue";
import NoteCommentsList from "./NoteCommentsList";
import gql from "graphql-tag";
import {
  injectActiveEmployee,
  hasActiveEmployeeIdAccess,
} from "@/mixins/employee";

export default {
  NOTE_COMMENTS: gql`
    query NOTE_COMMENTS($storeId: ID!, $noteId: ID!) {
      noteComments(storeId: $storeId, noteId: $noteId) {
        id
        body
        createdAt
        employeeId
        likes {
          id
          createdAt
          employee {
            id
            fullName
          }
        }
      }
    }
  `,

  components: { VxQuery, AddCommentInput, NoteCommentsList },
  mixins: [injectActiveEmployee, hasActiveEmployeeIdAccess],

  props: {
    noteId: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    addCommentText: "",
    addCommentLoading: false,
    addCommentErrors: [],
    query: undefined,
  }),

  computed: {
    queryVariables() {
      return {
        storeId: this.$route.params.storeId,
        noteId: this.noteId,
      };
    },
  },

  methods: {
    async onCommentSubmit(v) {
      this.addCommentLoading = true;

      try {
        await this.mutateCreateComment(v);
        this.addCommentText = "";
      } catch (error) {
        this.addCommentErrors = [error];
      } finally {
        this.addCommentLoading = false;
      }
    },
    mutateCreateComment(body) {
      const vm = this;
      return this.$apollo.mutate({
        mutation: gql`
          mutation ADD_NOTE_COMMENT($input: CreateNoteCommentInputObject!) {
            createNoteComment(input: $input) {
              newNoteComment: noteComment {
                id
                body
                createdAt
                employeeId
                likes {
                  employee {
                    id
                    fullName
                  }
                }
              }
              errors
            }
          }
        `,
        variables: {
          input: {
            noteId: this.noteId,
            employeeId: this.activeEmployeeId,
            body: body.trim(),
          },
        },
        update: (
          cache,
          {
            data: {
              createNoteComment: { newNoteComment, errors },
            },
          }
        ) => {
          // Read noteComments from the cache and add the
          // new comment to the data.
          const { noteComments } = cache.readQuery({
            query: this.$options.NOTE_COMMENTS,
            variables: this.queryVariables,
          });
          const data = {
            noteComments: [...noteComments, newNoteComment],
          };
          // If nothing changed, return
          if (noteComments.length === data.noteComments.length) return;

          cache.writeQuery({
            query: this.$options.NOTE_COMMENTS,
            variables: this.queryVariables,
            data,
          });

          // Increase the comment count on the note
          const fragment = gql`
            fragment CommentCount on Note {
              id
              commentsCount
            }
          `;
          const id = `Note:${vm.noteId}`;
          const noteFragment = cache.readFragment({
            id,
            fragment,
          });
          if (noteFragment) {
            const newCount = (noteFragment.commentsCount || 0) + 1;
            const updatedNote = {
              ...noteFragment,
              commentsCount: newCount,
            };
            const writeFragmentResult = cache.writeFragment({
              id,
              fragment,
              data: updatedNote,
            });
            const ch = vm.$pusher.channel(
              vm.$store.getters["sockets/storePresenceName"]
            );
            ch &&
              ch.trigger("client-note-comment-added", {
                id: vm.noteId,
                commentsCount: newCount,
              });
            console.log("fin");
          }
        },
      });
    },
    // Hack for getting the SmartQuery into the JS
    setQuery(query) {
      this.query = query;
    },
    // Public method for outside components to call
    refetch() {
      this.query && this.query.refetch();
    },
  },
};
</script>
