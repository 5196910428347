<template>
  <v-lazy>
    <v-slide-x-transition>
      <v-hover>
        <!-- TODO: handle manager content -->
        <v-card
          v-show="!computedHidden"
          slot-scope="{ hover }"
          data-testid="noteCard"
          :class="[cardClasses, `elevation-${hover ? 0 : 8}`, `elevation-3`]"
          flat
          hover
        >
          <NoteAuthor
            :employee-id="(note.employee && note.employee.id) || null"
            :note="note"
            @edit="$emit('edit', note)"
          />
          <v-card-text class="pt-0 text--primary">
            <div :class="bodyClasses" v-html="noteBody" />
          </v-card-text>
          <v-card-actions v-if="expanded" :class="engagementClasses">
            <v-btn
              text
              icon
              :class="
                isLikedByActiveEmployee ? 'primary--text' : 'ui--text lighten-2'
              "
              :loading="isLoading(loadingKey)"
              data-testid="noteCardLikeBtn"
              @click="like"
            >
              <v-icon size="18" v-text="'$vuetify.icons.thumbsUp'" />
            </v-btn>
            <NoteLikes :likes="note.likes" />
            <!-- ^ Toggle Comments Button -->
            <v-spacer />
            <ToggleCommentsButton
              data-testid="noteCardCommentBtn"
              :value="note.commentsCount"
              text
              small
              @click="toggleComments"
            />
            <!-- $ Toggle Comments Button -->
          </v-card-actions>
          <template v-if="showComments">
            <v-divider />
            <v-card-text>
              <NoteComments ref="noteComments" :note-id="note.id" />
            </v-card-text>
          </template>
        </v-card>
      </v-hover>
    </v-slide-x-transition>
  </v-lazy>
</template>

<script>
import NoteLikes from "@/components/daybook/NoteLikes";
import NoteAuthor from "@/components/daybook/NoteAuthor";
import NoteComments from "./DayBookView/NoteComments/NoteComments";
import ToggleCommentsButton from "./DayBookView/NoteCard/ToggleCommentsButton";

import { get, max } from "lodash";

import { format, parseISO } from "date-fns";
import {
  hasActiveEmployeeIdAccess,
  injectActiveEmployee,
} from "@/mixins/employee";
import { hasGlobalLoaderAccess } from "@/mixins/ui";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import { LIKE_NOTE } from "@/graphql/note/mutations.gql";
import { materialDate } from "@/utils/datetime";
import { parseIfJson } from "@/utils/lib";
import gql from "graphql-tag";

export default {
  name: "NoteCard",
  components: { NoteLikes, NoteAuthor, NoteComments, ToggleCommentsButton },
  mixins: [
    hasGlobalLoaderAccess,
    hasSnackbarAccess,
    hasActiveEmployeeIdAccess,
    injectActiveEmployee,
  ],
  props: {
    note: {
      type: Object,
      default: null,
    },
    prominent: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    condensed: {
      type: Boolean,
      default: false,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      defaut: false,
    },
  },
  data: () => ({
    showComments: false,
  }),
  computed: {
    authorName() {
      return get(this.note, "employee");
    },
    noteBody() {
      // TODO: removed $sanitize from the front end.
      // need to handle HTML on the backend (allow formatting, images, etc)
      return this.note.body.replace(/\n/g, "<br />");
    },
    publishedAt() {
      return format(parseISO(this.note.businessDate), "iiii, MMM d");
    },
    cardClasses() {
      var list = ["mx-auto rai-note"];
      if (this.expanded) {
        list.push("rai-note--body__expanded elevation-0 ui-lighten5 mb-2 mt-0");
      }
      if (!this.expanded) {
        list.push("ui-lighten3 mb-2 mt-0");
      }
      return list.join(" ");
    },
    bodyClasses() {
      var list = ["body-1 rai-note--body"];
      if (this.prominent && !this.expanded) {
        list.push("rai-note--body__condensed rai-note--body__lines-6");
      }
      if (this.condensed && !this.expanded) {
        list.push("rai-note--body__condensed rai-note--body__lines-2");
      }
      return list.join(" ");
    },
    engagementClasses() {
      return "";
      // return ["px-0"].join(" ")
    },
    // TODO: handle manager content
    computedHidden() {
      return (
        this.hidden ||
        (this.isManagerOnly && !this.activeEmployeeCan.seeManagerContent)
      );
    },
    isManagerOnly() {
      return get(this.note, "isManagerOnly");
    },
    loadingKey() {
      return `note-like-${this.note.id}`;
    },
    likes() {
      return get(this.note, "likes", []);
    },
    activeEmployeeLike() {
      return this.likes.find(
        (l) => l.employee.id === this.activeEmployeeId.toString()
      );
    },
    isLikedByActiveEmployee() {
      return this.activeEmployeeLike;
    },
    likedDate() {
      return (
        this.isLikedByActiveEmployee &&
        get(this.activeEmployeeLike, "createdAt")
      );
    },
    likeButtonText() {
      const date = this.likedDate && materialDate(this.likedDate);
      let dateStr;
      if (date) {
        dateStr = /\d/.test(date) ? `on ${date}` : date;
      }
      return (date && `You liked this ${dateStr}`) || "Like";
    },
    toggleCommentsText() {
      return (this.showComments && `Hide comments `) || `Show comments`;
    },
  },
  mounted() {
    this.subscribeToNoteCommentUpdates();
  },
  beforeDestroy() {
    this.unsubcribeToNoteCommentUpdates();
  },
  methods: {
    format,
    expand() {
      this.expanded = !this.expanded;
    },
    like() {
      if (this.isLikedByActiveEmployee) return false;
      this.loading(this.loadingKey);
      this.$apollo
        .mutate({
          mutation: LIKE_NOTE,
          variables: {
            id: this.note.id,
            employeeId: this.activeEmployeeId,
          },
        })
        .then(() => true)
        .catch((err) => {
          this.showSnackbar({
            text: `There was an error liking this note. Error: ${err}`,
          });
          this.loaded(this.loadingKey);
          return false;
        })
        .then((successful) => {
          this.loaded(this.loadingKey);
        });
    },
    toggleComments() {
      return (this.showComments = !this.showComments);
    },
    // Bind an event handler to `client-note-comment-added` event
    subscribeToNoteCommentUpdates() {
      const channel = this.$pusher.channel(
        this.$store.getters["sockets/storePresenceName"]
      );
      channel &&
        channel.bind(
          "client-note-comment-added",
          this.handleNoteCommentUpdateReceived
        );
    },
    // Unbind an event handler to `client-note-comment-added` event
    unsubcribeToNoteCommentUpdates() {
      const channel = this.$pusher.channel(
        this.$store.getters["sockets/storePresenceName"]
      );
      channel &&
        channel.unbind(
          "client-note-comment-added",
          this.handleNoteCommentUpdateReceived
        );
    },
    // Update the note fragment
    // Refetch noteComments query
    //
    // data: String | Object
    handleNoteCommentUpdateReceived(data) {
      // Parse event data
      data = parseIfJson(data);

      // Update the note fragment with the new `commentsCount`
      const cache = this.$apollo.getClient();
      const id = `Note:${data.id}`;
      const fragment = gql`
        fragment NoteCommentsCount on Note {
          id
          commentsCount
        }
      `;

      const noteFragment = cache.readFragment({
        id,
        fragment,
      });
      if (!noteFragment)
        return console.log(
          "Received note comments update, but note is not in cache. Ignoring."
        );

      cache.writeFragment({
        id,
        fragment,
        data: {
          ...noteFragment,
          commentsCount: max([noteFragment.commentsCount, data.commentsCount]),
        },
      });

      // Tell the `noteComments` component to refetch its comments query
      this.$refs.noteComments && this.$refs.noteComments.refetch();
    },
  },
};
</script>
