<template>
  <v-card flat class="ml-1 my-5">
    <v-skeleton-loader type="list-item-avatar-two-line" width="40%" />
    <v-spacer class="my-4" />
    <v-skeleton-loader type="paragraph" class="ml-5 mr-12" />
    <v-row class="justify-center">
      <v-col cols="4">
        <v-skeleton-loader
          type="button"
          style="width: 100% !important; padding: 0 20px; margin-top: 40px"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "SkeletonNoteCard",
};
</script>

<style></style>
