import { computed, inject } from "vue";
import i18n from "@/plugins/vue-i18n";

export const makeStatusProps = () => ({
  employee: {
    type: Object,
    required: true,
  },
  hideStatus: {
    type: Boolean,
    default: null,
  },
});

/**
 * @typedef {Object} Employee
 * @property {Boolean} clockedIn
 * @property {Boolean} isOnBreak
 *
 * @typedef {Object} EmployeeStatusProps
 * @property {Employee} employee
 * @property {Boolean} [hideStatus]
 */

/**
 * @param {EmployeeStatusProps} props - employee status props
 * @param {String} [name] - component name in kebab case
 */
export function useStatus(props, name = "employee-status") {
  const currentStore = inject("currentStore", () => ({
    usesExternalScheduler: false,
  }))();

  const isOnBreak = computed(
    () => props.employee.isOnBreak && props.employee.clockedIn
  );

  const isClockedIn = computed(
    () => props.employee.clockedIn && !props.employee.isOnBreak
  );

  const isClockedOut = computed(
    () => !props.employee.clockedIn && !props.employee.isOnBreak
  );

  const status = computed(() => {
    const hideStatus = props.hideStatus ?? !currentStore.usesExternalScheduler;

    if (hideStatus) {
      return "";
    } else if (isOnBreak.value) {
      return i18n.t("auth.teamMode.employeeStatus.breakText");
    } else if (isClockedIn.value) {
      return i18n.t("auth.teamMode.employeeStatus.clockedInText");
    }

    return i18n.t("auth.teamMode.employeeStatus.clockedOutText");
  });

  const statusClasses = computed(() => ({
    [`${name}--break`]: status.value && isOnBreak.value,
    [`${name}--clocked-in`]: status.value && isClockedIn.value,
    [`${name}--clocked-out`]: status.value && isClockedOut.value,
  }));

  return {
    status,
    statusClasses,
    isOnBreak,
    isClockedIn,
    isClockedOut,
  };
}
