<template>
  <div>
    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          :loading="loading"
          x-small
          rounded
          text
          :color="color"
          v-on="on"
          @click="likeComment"
        >
          {{ likes.length }}
          <v-icon size="10px" class="ml-2" v-text="`$vuetify.icons.thumbsUp`" />
        </v-btn>
      </template>
      <div class="note-comment__liked-by" v-text="likedBy" />
    </v-tooltip>
  </div>
</template>
<style lang="scss">
.note-comment__liked-by {
  white-space: pre-line;
}
</style>

<script>
import Base from "./Base";
import { sortByDate } from "@/utils/datetime";
import { chain } from "@/utils/lib";

export default {
  extends: Base,

  computed: {
    color() {
      return (
        (this.likes.find((l) => l.employee.id === this.activeEmployeeId) &&
          "primary") ||
        undefined
      );
    },
    likedBy() {
      return chain(this.likes)
        .do(sortByDate)
        .value()
        .map((l) => l.employee.fullName)
        .join("\n");
    },
  },
};
</script>
