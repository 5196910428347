<template>
  <v-menu v-model="menu" v-bind="$attrs" :attach="computedAttach">
    <template #activator="{ on }">
      <v-btn
        icon
        text
        v-bind="$attrs"
        :loading="$attrs.loading"
        :disabled="disabled"
        v-on="on"
        @click.stop
      >
        <v-icon v-text="`$vuetify.icons.more`" />
      </v-btn>
    </template>
    <slot :hide="hide"></slot>
  </v-menu>
</template>
<style>
.v-menu__content {
  background: white;
}
</style>
<script>
export default {
  inheritAttrs: false,
  props: {
    attachToParent: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    menu: false,
    mounted: false,
  }),
  computed: {
    computedAttach() {
      if (this.mounted && this.attachToParent) return this.$parent.$el;

      return undefined;
    },
  },
  mounted() {
    this.mounted = true;
  },
  methods: {
    hide() {
      this.menu = false;
    },
  },
};
</script>
