<template>
  <v-fade-transition>
    <div class="add-comment-input">
      <v-text-field
        v-bind="textFieldAttrs"
        outlined
        dense
        hide-details
        label="Reply"
        class="add-comment-input__input"
        data-testid="addCommentField"
        v-on="$listeners"
        @keyup.enter="submit"
      >
        <template #append>
          <EmojiPicker @select="onEmojiSelect" />
        </template>
      </v-text-field>
      <v-btn
        color="primary"
        data-testid="addCommentSubmitBtn"
        :disabled="disabled"
        text
        icon
        :loading="$attrs.loading"
        @click="submit"
      >
        <v-icon v-text="`$vuetify.icons.sendText`" />
      </v-btn>
    </div>
  </v-fade-transition>
</template>

<style lang="scss">
.add-comment-input {
  position: relative;
  display: grid;
  grid-template-columns: auto 32px;
  grid-gap: 4px;
  align-items: center;

  .v-input__append-inner {
    margin-top: 2px !important;
  }

  .message-input__emoji-button {
    max-width: 40px;
    min-width: 40px !important;
    padding: 0 !important;
    height: auto;
  }
}
</style>

<script>
import EmojiPicker from "@/views/Texting/AppView/components/EmojiPickerMenu";
export default {
  components: {
    EmojiPicker,
  },

  inheritAttrs: false,

  computed: {
    textFieldAttrs() {
      return {
        ...this.$attrs,
        loading: false,
        disabled: this.$attrs.loading,
      };
    },
    disabled() {
      return !this.$attrs.value || this.$attrs.value.length < 2;
    },
  },

  methods: {
    submit() {
      this.$emit("submit", this.$attrs.value);
    },
    onEmojiSelect(emoji) {
      this.$emit("input", this.$attrs.value + emoji.native);
    },
  },
};
</script>
