import HasLikes from "./HasLikes";
import HasNoLikes from "./HasNoLikes";
import { injectActiveEmployee } from "@/mixins/employee";

export default {
  functional: true,
  inheritAttrs: false,
  mixins: [injectActiveEmployee],
  render(h, ctx) {
    const component = ctx.props.likes.length ? HasLikes : HasNoLikes;

    return h(component, {
      props: ctx.props,
      ...ctx.data,
    });
  },
};
