import gql from "graphql-tag";
import { defineComponent } from "vue";
import EmployeeAvatar from "./EmployeeAvatar.vue";

const EMPLOYEE_AVATAR_QUERY = gql`
  query EMPLOYEE_AVATAR_QUERY($id: ID!) {
    employee(id: $id) {
      id
      fullName
      avatarUrl
      drsEmployeeCode
      clockedIn
      isOnBreak
    }
  }
`;

export default defineComponent({
  name: "EmployeeAvatarQuery",
  functional: true,
  props: {
    employeeId: {
      type: [Number, String],
      required: true,
    },
    hideStatus: {
      type: Boolean,
      default: true,
    },
  },
  render(createElement, context) {
    return createElement("ApolloQuery", {
      props: {
        query: EMPLOYEE_AVATAR_QUERY,
        variables: { id: context.props.employeeId },
      },
      scopedSlots: {
        default: ({ result: { loading, data } }) => {
          return createElement(EmployeeAvatar, {
            props: {
              loading,
              employee: data?.employee ?? {},
              hideStatus: context.props.hideStatus,
              ...context.data.attrs,
            },
          });
        },
      },
    });
  },
});
