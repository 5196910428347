<template>
  <div class="note-likes grey--text" data-testid="noteLikes">
    {{ likedText }}
    <v-tooltip v-if="others.length > 0" top>
      <template v-slot:activator="{ on }">
        <span class="primary--text" v-on="on"
          >and {{ others.length }} other{{
            others.length === 1 ? "" : "s"
          }}</span
        >
      </template>
      <span>{{ others.join(", ") }}</span>
    </v-tooltip>
  </div>
</template>

<style>
.note-likes {
  font-size: 0.85rem;
}
</style>

<script>
import { hasActiveEmployeeIdAccess } from "@/mixins/employee";
import { chain } from "lodash";
export default {
  name: "NoteLikes",
  mixins: [hasActiveEmployeeIdAccess],
  props: {
    likes: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    activeEmployeeLike() {
      return (
        (this.likes &&
          !!this.likes.find(
            (l) =>
              (l.employeeId || l.employee.id) ===
              this.activeEmployeeId.toString()
          )) ||
        false
      );
    },
    likedText() {
      if (this.likedNames.length === 0) return "";
      if (this.likedNames.length === 1)
        return this.likedNames.includes("You")
          ? this.likedNames.join(" liked this")
          : this.likedNames.join();
      // if (this.likedNames.length === 1) return this.likedNames.join()
      if (this.likedNames.length === 2) return this.likedNames.join(" and ");
      return this.likedNames.slice(0, 2).join(", ");
      // const others = this.likedNames.slice(2).length
      // return (
      //   this.likedNames.slice(0, 2).join(", ") +
      //   ` and ${others} other${others === 1 ? "" : "s"}`
      // )
    },
    likedNames() {
      return chain(
        (this.likes && [
          (this.activeEmployeeLike && "You") || null,
          ...this.likes
            .filter(
              (l) =>
                (l.employeeId || l.employee.id) !==
                this.activeEmployeeId.toString()
            )
            .map((l) => l.employee.fullName),
        ]) ||
          []
      )
        .compact()
        .value();
    },
    others() {
      return this.likedNames.slice(2);
    },
  },
};
</script>
