import store from "@/store";
import i18n from "@/plugins/vue-i18n";
import { defineComponent } from "vue";
import { EMPLOYEE_ACCESS_STATUS } from "@/store/modules/teamAuth";
import Vue from "vue";
import { injectActiveEmployee } from "@/mixins/employee";

export function genListeners() {
  return {
    mouseleave() {
      Vue.prototype.$tooltip.hide();
    },
    mouseenter(e) {
      const position = e.target.getBoundingClientRect();
      setTimeout(() => {
        Vue.prototype.$tooltip.show({
          message: "This requires manager login",
          positionX: position.x,
          positionY: position.y + position.height / 2,
          left: true,
          absolute: true,
          transition: "none",
        });
      });
    },
  };
}

export function handler(action, checkManager = false) {
  if (!store.getters["auth/isTeamModeLocked"] && !checkManager) {
    return Promise.resolve(action());
  }

  const needManagerText =
    "auth.employeeConfirm.employeeConfirmLink.actionNeedsManagerError";

  if (!store.getters["auth/isTeamModeLocked"] && checkManager) {
    return new Promise((resolve) => {
      const isManager = store.getters["auth/isManager"];
      isManager
        ? action()
        : store.dispatch("snackbar/showSnackbar", {
            text: i18n.t(needManagerText),
          });
      resolve();
    });
  }

  return new Promise((resolve) => {
    store.dispatch("auth/employeeAccessNeeded");
    const unwatch = store.watch(
      (state) => state.auth.employeeAccessStatus,
      (value, oldValue) => {
        if (oldValue !== EMPLOYEE_ACCESS_STATUS.requiredEmployeeAccess) {
          return;
        }

        const messageKey =
          "auth.employeeConfirm.employeeConfirmLink.actionNeedsEmployeeError";
        const isManager = store.getters["auth/isManager"];

        if (value === EMPLOYEE_ACCESS_STATUS.hasEmployee) {
          if (checkManager) {
            if (isManager) {
              action();
            } else {
              store.dispatch("snackbar/showSnackbar", {
                text: i18n.t(needManagerText),
              });
            }
          } else {
            action();
          }
        } else {
          store.dispatch("snackbar/showSnackbar", {
            text: i18n.t(messageKey),
          });
        }

        unwatch();
        resolve();
      }
    );
  });
}

export default defineComponent({
  name: "EmployeeAction",
  functional: true,
  mixins: [injectActiveEmployee],
  props: {
    checkManager: {
      type: Boolean,
      default: false,
    },
  },
  render(_, context) {
    let canSeeManagerContent =
      context.injections.computedActiveEmployeeCan().seeManagerContent;

    return context.scopedSlots.default?.call(this, {
      handleAction: (action) => handler(action, context.props.checkManager),
      isDisabled: !canSeeManagerContent,
      listeners: !canSeeManagerContent && genListeners(),
    });
  },
});
